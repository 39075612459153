import React, { useContext } from "react";
import SEO from "../../components/seo";
import CustomAuth from "../../components/custom-auth";
import TestLookup from "../../components/test-lookup";
import { UserContext } from "../../provider/usercontextprovider";
import { UserTenantProvider } from "../../provider/usertenantprovider";

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isManager =
    userContext.username &&
    userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("Manager"); //Checks for TenantAdmin instead of <TENANTHERE>Manager role. DET-492

  return (
    <CustomAuth>
      <SEO title="Medical Test Lookup" />
      <UserTenantProvider>
        {isManager ? <TestLookup userContext={userContext} /> : <p>User is not a manager</p> }
      </UserTenantProvider>
    </CustomAuth>
  );
}
export default ProtectedPage;
