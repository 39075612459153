// extracted by mini-css-extract-plugin
export var form = "enroller-module--form--vakp5";
export var inputWrapper = "enroller-module--inputWrapper--XCdnK";
export var buttonWrapper = "enroller-module--buttonWrapper--CvZ+e";
export var label = "enroller-module--label--g4f2m";
export var input = "enroller-module--input--PKKvo";
export var testFoundAlert = "enroller-module--testFoundAlert--2XVf1";
export var resultsList = "enroller-module--resultsList--odLGf";
export var resultsItem = "enroller-module--resultsItem--7B8ha";
export var mainContent = "enroller-module--mainContent--6OX0S";
export var enrolleeName = "enroller-module--enrolleeName--TCWqI";
export var enrolleeId = "enroller-module--enrolleeId--6j0ie";
export var testResult = "enroller-module--testResult--IaYpX";
export var Negative = "enroller-module--Negative--HxHM2";
export var Positive = "enroller-module--Positive--u4rMi";
export var Pending = "enroller-module--Pending--VTnXx";
export var testDetails = "enroller-module--testDetails--uG7Nf";
export var date = "enroller-module--date--kYj5u";
export var lookupItem = "enroller-module--lookupItem--HFyVg";
export var content = "enroller-module--content--qIH7d";
export var result = "enroller-module--result--DVk-P";
export var icon = "enroller-module--icon--El8EE";
export var pending = "enroller-module--pending--+QAiW";
export var Rejected = "enroller-module--Rejected--wiujm";
export var Accepted = "enroller-module--Accepted--Q-UAn";