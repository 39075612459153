import React from "react"
import * as enrollerStyles from "./enroller.module.css"

const ResultsItem = ({ test }) => {
    return (
        <div className={enrollerStyles.resultsItem}>
            <div className={enrollerStyles.mainContent}>
                <p className={enrollerStyles.enrolleeName}>
                    {test.enrolleeFirstName} {test.enrolleeLastName}
                    <span className={enrollerStyles.enrolleeId}>{test.enrolleeIdentifier}</span>
                </p>
                <div className={enrollerStyles.testResult}>
                    <p className={`${test.result ? enrollerStyles[test.result] : enrollerStyles.Pending}`}>
                        {test.result ? test.result : "Pending"}
                    </p>
                </div>
            </div>
            <div className={enrollerStyles.testDetails}>
                <div>
                    <p>
                        {test.sampleCode}
                    </p>
                    <p>
                        {test.panelName}
                    </p>
                </div>
                {!test.orderDate &&
                    <div className={enrollerStyles.date}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p>
                            Ordered on {new Date(test.orderDate).toLocaleDateString()}
                        </p>
                    </div>
                }
                {test.dateOfCollection &&
                    <div className={enrollerStyles.date}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p>
                            Tested on {new Date(test.dateOfCollection).toLocaleDateString()}
                        </p>
                    </div>
                }
                {test.resultDate &&
                    <div className={enrollerStyles.date}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p>
                            Result on {new Date(test.resultDate).toLocaleDateString()}
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}

export default ResultsItem