import React, { useEffect, useContext, useState } from "react";
import ResultsItem from "./resultsItem";
import Select from "react-select";
import { API, graphqlOperation } from "aws-amplify";
import { LoadMaskContext } from "../provider/loadmaskprovider";
import * as enrollerStyles from "./enroller.module.css";
import * as buttonStyles from "./button.module.css";
import { UserTenantContext } from "../provider/usertenantprovider";

const listMedicalTest = /* GraphQL */ `
query listMedicalTestsByTenantIdAndEnrolleeIdentifier(
    $tenantId: String
    $enrolleeIdentifier: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    ) {
    listMedicalTestsByTenantIdAndEnrolleeIdentifier(tenantId: $tenantId, enrolleeIdentifier: $enrolleeIdentifier, limit: $limit, nextToken: $nextToken) {
        items {
            id
            dateOfCollection
            orderDate
            panelName
            enrolleeFirstName
            enrolleeLastName
            enrolleeIdentifier
            result
            resultDate
            sampleCode
        }
    }
}`

const TestLookup = () => {
    const [isLoading, setIsLoading] = useContext(LoadMaskContext);
    const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
    const [state, setState] = useState({
        tenantId: null,
        enrolleeId: null,
        nextToken: null,
        results: [],
    });

    const options = userTenantContext.filter(t => t.tenantRole.includes("Admin")).map(tenant => {
      return {
        value: tenant.tenantObj.name,
        label: tenant.tenantObj.prettyName,
      }
    });

    const lookup = async clearToken => {
        const filter = {
            tenantId: state.tenantId,
            enrolleeIdentifier: {
                eq: state.enrolleeId ? state.enrolleeId : null,
            },
            nextToken: clearToken ? null : state.nextToken,
        }

        setIsLoading(true)
        try {
            let results = await API.graphql(graphqlOperation(listMedicalTest, filter))
            console.log(results)
            setState({
                ...state,
                results: results.data.listMedicalTestsByTenantIdAndEnrolleeIdentifier.items,
                nextToken: results.data.listMedicalTestsByTenantIdAndEnrolleeIdentifier.nextToken,
            })
        } catch {
            setState({
                ...state,
                results: [],
                nextToken: null,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <h2>Lookup Form</h2>

            <div className={enrollerStyles.form}>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="clientSite">
                        Tenant
                    </label>
                    <Select
                        options={options}
                        onChange={e => setState({ ...state, tenantId: e.value })}
                    />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label
                        className={enrollerStyles.label}
                        htmlFor="enrolleeIdentifier"
                    >
                        Enrollee ID
              </label>
                    <input
                        className={enrollerStyles.input}
                        type="text"
                        name="enrolleeIdentifier"
                        disabled={!state.tenantId}
                        onChange={e =>
                            setState({ ...state, enrolleeId: e.currentTarget.value })
                        }
                    />
                </div>
            </div>
            <div className={enrollerStyles.inputWrapper}>
                <button
                    type="button"
                    className={buttonStyles.button}
                    disabled={!state.tenantId}
                    onClick={e => lookup(true)}
                >
                    Lookup
            </button>
            </div>
            <div className={`${state.results.length > 0 ? enrollerStyles.resultsList : ""}`}>
                {state.results.map(r => (
                    <ResultsItem key={r.id} test={r} />
                ))}
                <div style={{ textAlign: "center" }}>
                    <button
                        className={buttonStyles.button}
                        style={{
                            display: state.nextToken == null ? "none" : "initial",
                        }}
                        onClick={e => {
                            e.preventDefault()
                            lookup(false)
                        }}
                    >
                        Load More
              </button>
                </div>
            </div>
        </div>
    );
}

export default TestLookup;